import API2 from '../utils/api';

export default {
  name: 'HelpApi',
  methods: {
    async getHelpData(type, language) {
      const path = `/help?type=${type}&language=${language}`;
      return await new Promise((resolve, reject) => {
        API2.get(path).then(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
  },
};
